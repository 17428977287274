import { useChannelConfig } from "@/hooks/use-channel-config";
import { useDefaultEntity } from "@/hooks/use-default-entity";
import { useTextOverrides } from "@/hooks/use-text-overrides";
import { AGREEMENT_COOKIE } from "@/lib/constants";
import { stepper } from "@/slices/stepper";
import { useDispatch } from "@/store";
import { AgreementReader, AgreementLayout } from "@kenai/core";

import dynamic from "next/dynamic";

const PDFReader = dynamic(
  () => import("@kenai/core/src/components/pdf-reader/pdf-reader")
);

export default function Agreement() {
  const channelConfig = useChannelConfig();
  const defaultEntity = useDefaultEntity();
  const textOverrides = useTextOverrides();
  const dispatch = useDispatch();

  return (
    <div className="h-screen">
      <AgreementLayout>
        <AgreementReader
          padding={false}
          dialogTitle={"Employee Agreement"}
          headingText={
            textOverrides.agreementTitle || "Welcome to the Employee Induction"
          }
          description={textOverrides.agreementSubTitle}
          identifier={
            channelConfig._agreementIdentifier || Date.now().toString()
          }
          cookieName={AGREEMENT_COOKIE}
          companyName={defaultEntity?.companyName || ""}
          companyLogo={channelConfig.logo}
          onAgreementContinue={() => {
            dispatch(stepper.nextStep());
          }}
          mandatoryRead={channelConfig.mandatoryAgreementRead}
        >
          <PDFReader url={channelConfig.agreementLink} />
        </AgreementReader>
      </AgreementLayout>
    </div>
  );
}
