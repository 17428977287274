import { ExternalLinkIcon, S3Image } from "@kenai/core";
import { useMediaQuery } from "@kenai/core";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useCookie } from "next-cookie";
import { FC, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export const AgreementLayout: FC = ({ children }) => {
  return (
    <div className="agreement-layout h-full grid text-left py-10 md:py-20 place-content-center place-items-center">
      <div className="container">{children}</div>
    </div>
  );
};

export interface AgreementReaderProps {
  onAgreementContinue?: () => void;
  onAgreementAcceptToggle?: (checked: boolean) => void;
  disabled?: boolean;
  mandatoryRead?: boolean;

  dialogTitle?: string;

  headingText: string;
  description?: string;
  agreementLinkText?: string;

  padding?: boolean;

  companyLogo?: string;
  companyName?: string;
  cookieName: string;
  identifier: string;
}

export const AgreementReader: FC<AgreementReaderProps> = ({
  padding = true,
  ...props
}) => {
  const [agreementConfirmed, setAgreementConfirmed] = useState(false);
  const cookie = useCookie();
  const agreementCookie = cookie.get<string>(props.cookieName);

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [visible, setVisible] = useState(false);
  const [acceptContinue, setAcceptContinue] = useState(false);
  const [mandatoryRead, setMandatoryRead] = useState(
    props.mandatoryRead || false
  );

  useEffect(() => {
    setMandatoryRead(props.mandatoryRead || false);
  }, [props.mandatoryRead]);

  useEffect(() => {
    const agreementConfirmed = ["TEST-MODE", props.identifier].includes(
      agreementCookie || ""
    );
    setAgreementConfirmed(agreementConfirmed);
  }, [props.identifier, agreementCookie]);

  useEffect(() => {
    if (!agreementCookie && mandatoryRead) {
      setMandatoryRead(true);
    } else {
      setAgreementConfirmed(true);
    }
  }, [agreementCookie, mandatoryRead]);

  const handleContinue = () => {
    if (mandatoryRead) {
      if (agreementConfirmed) {
        props.onAgreementContinue?.();
      } else {
        setAcceptContinue(true);
        setVisible(true);
      }
    } else {
      props.onAgreementContinue?.();
    }
  };

  const handleAcceptAgreement = () => {
    cookie.set(props.cookieName, props.identifier);
    setAgreementConfirmed(true);
    setMandatoryRead(false);
    setVisible(false);
    if (acceptContinue) {
      props.onAgreementContinue?.();
    }
  };

  const AgreementText = (
    <Typography
      sx={{
        lineHeight: "42px",
      }}
      variant="body1"
      data-cy="accept-agreement"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setVisible(true);
      }}
    >
      I have read, understood and accepted the{" "}
      <Link
        tabIndex={1}
        role="button"
        onClick={() => setVisible(true)}
        onKeyDown={(e) =>
          e.key === "Enter" || e.code === "Space" ? setVisible(true) : null
        }
        style={{ lineHeight: "inherit" }}
      >
        {props.agreementLinkText || "agreement"}{" "}
        <Typography variant="body1" component="span" noWrap>
          <ExternalLinkIcon sx={{ fontSize: 14 }} />
        </Typography>
      </Link>
    </Typography>
  );

  const handleDenyAgreement = useCallback(() => {
    cookie.remove(props.cookieName);
    setAgreementConfirmed(false);
    if (props.mandatoryRead) {
      setMandatoryRead(true);
    }
  }, [props.mandatoryRead, agreementConfirmed]);

  return (
    <div>
      {props.companyLogo && (
        <S3Image
          src={props.companyLogo}
          width={200}
          height={100}
          alt={props.companyName}
          className="object-contain object-left"
        />
      )}
      <div className="mb-2 md:mb-4 mt-4">
        <Typography variant="h4" component="h1" data-cy="page-title">
          {props.headingText}
        </Typography>
        {props.description && (
          <Typography variant="subtitle1">{props.description}</Typography>
        )}

        <div className="mt-4">
          {!mandatoryRead || agreementConfirmed ? (
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={
                    {
                      "data-cy": "agreement-checkbox",
                    } as any
                  }
                  onClick={handleDenyAgreement} // only visible when checked
                  checked={agreementConfirmed}
                  disabled={props.disabled}
                />
              }
              label={AgreementText}
            />
          ) : (
            AgreementText
          )}
        </div>
      </div>
      <div className="action-container pt-8">
        <Button
          variant="contained"
          onClick={handleContinue}
          data-cy="next-step"
          tabIndex={2}
        >
          Continue
        </Button>
      </div>
      <Dialog open={visible} fullScreen={fullScreen} maxWidth="lg">
        <DialogTitle>
          {props.dialogTitle || "Agreement"}
          <IconButton
            aria-label="close"
            onClick={() => setVisible(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={!padding ? { padding: 0 } : undefined}>
          {props.children}
          {padding && <div className="mb-20" aria-hidden />}
          <div className="absolute bottom-0 right-0 z-10 pr-8 pb-5">
            {agreementConfirmed ? (
              <Button
                size="large"
                variant="contained"
                onClick={handleContinue}
                data-cy="next-step"
              >
                Continue
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                onClick={handleAcceptAgreement}
                data-cy="accept-agreement-button"
              >
                Accept
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
